import React from 'react';

import VrsLayout from '../components/VrsLayout.bs.js';
import SEO from '../components/Seo';
import VrsMainScreen from '../screens/VrsMainScreen__JsBridge.bs.js';

export default ({ pageContext: { listings } }) => (
  <VrsLayout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <VrsMainScreen listings={listings} />
  </VrsLayout>
);
