// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Listing = require("../hc/reason/models/Listing.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var VrsMainScreen = require("./VrsMainScreen.bs.js");

function VrsMainScreen__JsBridge$default(Props) {
  var listings = Props.listings;
  var listings$1 = React.useMemo((function () {
          return Belt_Array.map(listings, Listing.Contextual.fromJson);
        }), ([]));
  return React.createElement(VrsMainScreen.make, {
              listings: listings$1
            });
}

var $$default = VrsMainScreen__JsBridge$default;

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
