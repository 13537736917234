// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Image = require("../hc/reason/styleguide/components/Image/Image.bs.js");
var React = require("react");
var Gatsby = require("../vendor/Gatsby.bs.js");
var Gatsby$1 = require("gatsby");
var Currency = require("../hc/reason/styleguide/components/Currency/Currency.bs.js");
var VrsRoute = require("../VrsRoute.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var VrsListingCardModuleScss = require("./VrsListingCard.module.scss");

var css = VrsListingCardModuleScss;

function VrsListingCard(Props) {
  var listing = Props.listing;
  var photo = Belt_Option.flatMap(listing.photos, (function (x) {
          return Belt_Array.get(x, 0);
        }));
  var samplePrice = listing.samplePrice;
  return React.createElement("div", {
              className: css.container
            }, photo !== undefined ? React.createElement(Gatsby$1.Link, Gatsby.Link.makeProps(VrsRoute.listing(listing.id), css.photoLink, undefined, React.createElement($$Image.Background.make, {
                            className: css.photo,
                            url: photo.cloudUrl,
                            width: 330,
                            height: 160
                          }), undefined)) : null, React.createElement(Gatsby$1.Link, Gatsby.Link.makeProps(VrsRoute.listing(listing.id), css.content, undefined, null, undefined), React.createElement("div", {
                      className: css.title
                    }, listing.title), React.createElement("div", {
                      className: css.bottom
                    }, samplePrice !== undefined ? React.createElement("div", {
                            className: css.pricePerNight
                          }, React.createElement("span", {
                                className: css.pricePerNightValue
                              }, React.createElement(Currency.WithoutCents.make, {
                                    children: samplePrice
                                  })), React.createElement("span", {
                                className: css.pricePerNightLabel
                              }, "/ per night")) : null)));
}

var make = VrsListingCard;

exports.css = css;
exports.make = make;
/* css Not a pure module */
