// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Button = require("../hc/reason/styleguide/components/Button/Button.bs.js");
var MoveTo = require("../hc/reason/bindings/MoveTo.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var VrsListingCard = require("../components/VrsListingCard.bs.js");
var VrsMainScreenModuleScss = require("./VrsMainScreen.module.scss");

var css = VrsMainScreenModuleScss;

function VrsMainScreen(Props) {
  var listings = Props.listings;
  var moverRef = React.useRef(undefined);
  var cardsRef = React.useRef(null);
  React.useEffect((function () {
          moverRef.current = Caml_option.some(MoveTo.make(20));
          
        }), ([]));
  return React.createElement("div", {
              className: css.container
            }, React.createElement("div", {
                  className: css.hero
                }, React.createElement("div", {
                      className: css.heroContent
                    }, React.createElement("div", {
                          className: css.heroText
                        }, React.createElement("div", {
                              className: css.heroMainHeading
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "vrs.main.heading",
                                  defaultMessage: "Let's Get Your Next Vacation to the Next Level!"
                                })), React.createElement("div", {
                              className: css.heroSubHeading
                            }, React.createElement(ReactIntl.FormattedHTMLMessage, {
                                  id: "vrs.main.sub-heading",
                                  defaultMessage: "Book your perfect place on Hawaii for the lowest price. Today. Now."
                                })), React.createElement("div", {
                              className: css.heroCta
                            }, React.createElement(Button.make, {
                                  size: /* LG */2,
                                  color: /* Primary */0,
                                  onClick: (function (param) {
                                      var match = moverRef.current;
                                      var match$1 = cardsRef.current;
                                      if (match !== undefined && !(match$1 == null)) {
                                        Caml_option.valFromOption(match).move(match$1);
                                        return ;
                                      }
                                      
                                    }),
                                  children: "Book a Place"
                                }))))), React.createElement("div", {
                  ref: cardsRef,
                  className: css.cards
                }, Belt_Array.map(listings, (function (listing) {
                        return React.createElement(VrsListingCard.make, {
                                    listing: listing,
                                    key: String(listing.id)
                                  });
                      }))));
}

var make = VrsMainScreen;

exports.css = css;
exports.make = make;
/* css Not a pure module */
