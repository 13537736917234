// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function listing(listingId) {
  return "/listings/" + String(listingId);
}

exports.listing = listing;
/* No side effect */
